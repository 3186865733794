@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;0,800;1,100;1,200;1,300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
    font-family: 'Raleway', sans-serif;
    
    }
}